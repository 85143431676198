import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import {
    LineChart,
    Legend,
    XAxis,
    YAxis,
    Tooltip,
    Line,
    ReferenceLine,
    ResponsiveContainer,
    CartesianGrid
} from 'recharts';

class TicketTimesChart extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            data: [],
            loading: false
        };
    }

    async componentDidMount() {
        const result = await axios.get(
                this.props.resourcePath, {
                    headers: {'Accept': 'application/json'}
                });

        if (result.status !== 200) {
            return;
        }

        this.setState({data: result.data});
    }

    tooltipFormatter = (value, name, props) => {
        return [props.payload.labels[name], name];
    }

    render() {
        if (this.state.data.length === 0) {
            return null;
        }

        const lines = [];
        const strokeColors = ['#c1caff', '#e8d3ab', '#d0ace5', '#a0daee', '#4153a9'];
        const currentYear = new Date().getFullYear();

        const keys = Array.from(
                new Set(
                        this.state.data.flatMap(obj =>
                                Object.keys(obj).filter(key => key !== "name" && key !== "labels")
                        )
                )
        );

        keys.forEach((key, index) => {
            lines.push(<Line name={key} key={`line_${key}`} dataKey={key} strokeWidth={key == currentYear ? 2 : 1}
                             stroke={key == currentYear ? '#51AE2F' : strokeColors[index]}/>)
        })

        return (
                <div className='card'>
                    <div className='card-heading border bottom'>
                        <h4 className='card-title'>{this.props.title}</h4>
                    </div>
                    <div className='card-body'>
                        <ResponsiveContainer height={350}>
                            <LineChart data={this.state.data}>
                                <CartesianGrid strokeDasharray="3 3"/>
                                <XAxis dataKey='name' height={80} tick={{angle: -45, textAnchor: 'end'}} interval={0}/>
                                <YAxis domain={[0, 'dataMax']}/>
                                <Tooltip formatter={this.tooltipFormatter}/>
                                {lines}
                                {this.props.target &&
                                    <ReferenceLine y={this.props.target} label={{position: 'bottom',  value: 'Zielvorgabe'}} ifOverflow='extendDomain' stroke='#ff8c00' strokeWidth={2} />
                                }
                                <Legend verticalAlign="top" height={36}/>
                            </LineChart>
                        </ResponsiveContainer>
                    </div>
                </div>
        );
    }
}

TicketTimesChart.propTypes = {
    resourcePath: PropTypes.string,
    target: PropTypes.integer,
    title: PropTypes.string.isRequired
};

export default TicketTimesChart;